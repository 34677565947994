div[role='document'] {
  overflow-y: initial; }

.promotion-insert-dialog-class .promotion-validity-alert {
  display: block;
  font-size: 11px;
  padding-top: 8px;
  text-transform: uppercase;
  width: 85%; }

.promotion-insert-dialog-class .icon-info {
  font-size: 13px;
  float: left;
  left: 0;
  margin: 0 2px 10px 0;
  position: relative;
  top: 0; }

.promotion-insert-dialog-class .seller-container {
  margin-bottom: 14px; }

.promotion-insert-dialog-class .promotion-validity-container {
  margin-bottom: 22px; }

.promotion-insert-dialog-class .promotional-action-container {
  margin-bottom: auto; }

.promotion-insert-dialog-class .MuiDialog-paper-4 {
  overflow-y: initial; }

.promotion-insert-dialog-class .react-datepicker {
  border: none; }
  .promotion-insert-dialog-class .react-datepicker__header {
    color: #fff;
    border: none;
    background-color: #fff; }
  .promotion-insert-dialog-class .react-datepicker__triangle {
    display: none; }
  .promotion-insert-dialog-class .react-datepicker__time-container {
    border-color: #dcdbdc; }
  .promotion-insert-dialog-class .react-datepicker__navigation--next {
    border-left-color: #2f9bd4; }
  .promotion-insert-dialog-class .react-datepicker__navigation--previous {
    border-right-color: #2f9bd4; }
  .promotion-insert-dialog-class .react-datepicker__day, .promotion-insert-dialog-class .react-datepicker__time, .promotion-insert-dialog-class .react-datepicker__day-name, .promotion-insert-dialog-class .react-datepicker__time-name, .promotion-insert-dialog-class .react-datepicker__month-text {
    color: #464e54;
    font-family: "RobotoCondensed"; }
  .promotion-insert-dialog-class .react-datepicker__current-month, .promotion-insert-dialog-class .react-datepicker-time__header, .promotion-insert-dialog-class .react-datepicker-year-header {
    color: #2f9bd4;
    font-family: "RobotoCondensed";
    font-weight: normal;
    text-transform: uppercase; }
  .promotion-insert-dialog-class .react-datepicker__time-list-item--selected {
    background-color: #2f9bd4 !important; }
  .promotion-insert-dialog-class .react-datepicker__day--today {
    background-color: #dcdbdc;
    border-radius: 50%; }
  .promotion-insert-dialog-class .react-datepicker__day--keyboard-selected, .promotion-insert-dialog-class .react-datepicker__day--selected {
    border-radius: 50%;
    background-color: #2f9bd4;
    color: #fff;
    font-weight: normal; }

.promotion-insert-dialog-class .react-datepicker__day,
.promotion-insert-dialog-class .react-datepicker__day-name,
.promotion-insert-dialog-class .react-datepicker__time-name {
  line-height: 1.7rem; }

.promotion-insert-dialog-class .react-datepicker-popper {
  box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.19);
  text-transform: uppercase; }

.promotion-insert-dialog-class .react-datepicker__day-names,
.promotion-insert-dialog-class .react-datepicker__week {
  margin: 2px 0; }

.promotion-insert-dialog-class .promotion-insert-dialog .react-datepicker__day-names .react-datepicker__day-name {
  font-weight: bold; }

.promotion-insert-dialog-class #start_date,
.promotion-insert-dialog-class #end_date {
  color: transparent;
  cursor: default;
  padding-left: 50px;
  text-shadow: 0 0 0 #464e54; }
  .promotion-insert-dialog-class #start_date:focus,
  .promotion-insert-dialog-class #end_date:focus {
    outline: none; }
