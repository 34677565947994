.cycleInstance-commission-tr-cycles, .cycleInstance-commission-tr-period, .cycleInstance-commission-tr-average-commission, .cycleInstance-commission-tr-sales, .cycleInstance-commission-tr-sales-commission, .cycleInstance-commission-tr-return-commission, .cycleInstance-commission-tr-commission-balance, .cycleInstance-commission-tr-download {
  font-family: "RobotoCondensed";
  text-align: center; }

.cycleInstance-commission-tr .negative {
  color: #d8232a; }

.cycleInstance-commission-tr-cycles {
  width: 8em; }

.cycleInstance-commission-tr-period {
  width: 14em;
  text-align: left; }

.cycleInstance-commission-tr-average-commission {
  width: 9em; }

.cycleInstance-commission-tr-sales {
  width: 10em; }

.cycleInstance-commission-tr-sales-commission {
  width: 9em; }

.cycleInstance-commission-tr-return-commission {
  width: 9em; }

.cycleInstance-commission-tr-commission-balance {
  width: 11em; }

.cycleInstance-commission-tr-download {
  width: 13em;
  text-align: left; }
  .cycleInstance-commission-tr-download a {
    color: #71808b;
    font-size: 13px;
    letter-spacing: -0.6px;
    text-decoration: none; }
