.col.area {
  width: 50%; }

.col.default-rule {
  width: 33%; }

.form-group {
  position: relative; }
  .form-group .plan-name-info {
    text-transform: uppercase;
    position: absolute;
    font-size: 0.75em;
    top: 4px;
    right: 0;
    font-weight: 400; }
  .form-group .details label {
    width: 50%; }
