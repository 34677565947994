.sellers-accounts-table .seller-account.th-trading-name {
  width: 20%; }

.sellers-accounts-table .seller-account.th-cnpj {
  text-align: center;
  width: 20%; }

.sellers-accounts-table .seller-account.th-company-name {
  width: 30%; }

.sellers-accounts-table .seller-account.th-status {
  width: 8%; }

.sellers-accounts-table .seller-account.th-created-account {
  text-align: center;
  width: 23%; }

.sellers-accounts-table .table-td {
  font-size: 0.875em; }

.sellers-accounts-table .seller-account-tr-partner {
  font-weight: bold; }

.sellers-accounts-table .seller-account-tr-cnpj {
  text-align: center; }

.sellers-accounts-table .seller-account-tr-status {
  text-transform: uppercase; }
  .sellers-accounts-table .seller-account-tr-status .dot {
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px; }
  .sellers-accounts-table .seller-account-tr-status .active {
    background-color: #009161; }
  .sellers-accounts-table .seller-account-tr-status .inactive {
    background-color: #c6ccd1; }

.sellers-accounts-table .seller-account-tr-created-account {
  text-align: center;
  text-transform: uppercase; }
  .sellers-accounts-table .seller-account-tr-created-account .icon-warning,
  .sellers-accounts-table .seller-account-tr-created-account .error {
    color: #c20000; }
  .sellers-accounts-table .seller-account-tr-created-account .not-created {
    color: #ff9d00; }
