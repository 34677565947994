.detail-list-dialog .dialog-content {
  height: 320px;
  overflow: auto; }

.detail-list-dialog .grouped-list {
  list-style: none;
  margin-top: 18px;
  margin-left: 38px; }
  .detail-list-dialog .grouped-list .list-item .item {
    position: relative;
    margin-bottom: 11px; }
    .detail-list-dialog .grouped-list .list-item .item::before {
      position: absolute;
      content: "\2022";
      font-size: 1.375em;
      color: #148cc7;
      font-weight: bold;
      margin-left: -0.8em;
      top: -0.2em; }

.detail-list-dialog .dialog-footer {
  margin-top: 20px; }
