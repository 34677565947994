@import url(../../../components/form-components/InputText.css);
.react-autosuggest__suggestions-list li[aria-selected="true"] {
  background-color: #e2eaef; }

.form-group.seller-input-text.wrap-input-text.clearfix {
  margin-bottom: 10px;
  margin-top: 20px; }

.seller-input-text {
  background-color: #ebebeb;
  color: #156992;
  font-family: "RobotoCondensed"; }
  .seller-input-text .icon-search {
    font-size: 21px;
    top: 13px; }
  .seller-input-text .react-autosuggest__input {
    font-size: 16px; }
  .seller-input-text .react-autosuggest__input--focused {
    outline: none; }
  .seller-input-text .react-autosuggest__container {
    width: 92%;
    float: right; }
  .seller-input-text .react-autosuggest__suggestions-container--open {
    border: none;
    display: block;
    position: absolute;
    width: 89%;
    left: 33px;
    max-height: 270px;
    overflow-x: auto;
    background-color: #f7f7f7;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.4); }
    .seller-input-text .react-autosuggest__suggestions-container--open li {
      border-bottom: 1px solid #d9d9d9;
      cursor: pointer;
      font-size: 18px;
      padding: 16px 16px 16px 56px; }
      .seller-input-text .react-autosuggest__suggestions-container--open li:hover {
        background-color: #e2eaef; }
