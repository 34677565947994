.error-dialog {
  padding: 0; }
  .error-dialog-message {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.5;
    letter-spacing: normal;
    color: #71808b; }
  .error-dialog-header {
    background-color: #c20000;
    padding: 24px 32px 16px 32px; }
    .error-dialog-header .icon-close {
      color: #fff; }
    .error-dialog-header .icon {
      font-size: 22px;
      color: #fff; }
    .error-dialog-header .dialog-header-title {
      color: #fff;
      text-transform: uppercase; }
  .error-dialog-footer {
    padding: 32px 24px 24px; }
    .error-dialog-footer-btn-back, .error-dialog-footer-btn-back:hover {
      background-color: #c20000;
      color: #fff;
      width: 161px; }
    .error-dialog-footer-btn-export, .error-dialog-footer-btn-export:hover {
      background-color: #fff;
      position: relative;
      left: -125px; }
      .error-dialog-footer-btn-export span, .error-dialog-footer-btn-export:hover span {
        font-weight: 400; }
  .error-dialog .dialog-content {
    padding: 0 32px; }
