@charset "UTF-8";
@keyframes bounce_progress {
  0% {
    margin-left: -250px; }
  100% {
    margin-left: 250px; } }

@-o-keyframes bounce_progress {
  0% {
    margin-left: -250px; }
  100% {
    margin-left: 250px; } }

@-ms-keyframes bounce_progress {
  0% {
    margin-left: -250px; }
  100% {
    margin-left: 250px; } }

@-webkit-keyframes bounce_progress {
  0% {
    margin-left: -250px; }
  100% {
    margin-left: 250px; } }

@-moz-keyframes bounce_progress {
  0% {
    margin-left: -250px; }
  100% {
    margin-left: 250px; } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html,
#root {
  height: 100%;
  box-sizing: border-box; }

body {
  box-sizing: border-box;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif; }

.base-main-content {
  min-height: 100%; }

.base-main-pages {
  min-width: 1276px;
  margin: 0 1.757%;
  border: solid #d9d9d9;
  border-width: 0 1px; }

::selection {
  background-color: #fff;
  color: #f90; }

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none; }

.clearfix::after {
  content: "";
  display: table;
  clear: both; }

.content {
  padding: 24px; }

@font-face {
  font-family: "RobotoCondensed";
  src: url("../resources/fonts/RobotoCondensed/RobotoCondensed-Regular.ttf"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-Regular.eot?#iefix"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-Regular.eot"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-Regular.svg#RobotoCondensed-Regular");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "RobotoCondensed";
  src: url("../resources/fonts/RobotoCondensed/RobotoCondensed-Light.ttf"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-Light.eot?#iefix"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-Light.eot"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-Light.svg#RobotoCondensed-Light");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "RobotoCondensed";
  src: url("../resources/fonts/RobotoCondensed/RobotoCondensed-LightItalic.ttf"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-LightItalic.eot?#iefix"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-LightItalic.eot"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-LightItalic.svg#RobotoCondensed-LightItalic");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "RobotoCondensed";
  src: url("../resources/fonts/RobotoCondensed/RobotoCondensed-Italic.ttf"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-Italic.eot?#iefix"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-Italic.eot"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-Italic.svg#RobotoCondensed-Italic");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "RobotoCondensed";
  src: url("../resources/fonts/RobotoCondensed/RobotoCondensed-BoldItalic.ttf"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-BoldItalic.eot?#iefix"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-BoldItalic.eot"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-BoldItalic.svg#RobotoCondensed-BoldItalic");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "RobotoCondensed";
  src: url("../resources/fonts/RobotoCondensed/RobotoCondensed-Bold.ttf"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-Bold.eot?#iefix"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-Bold.eot"), url("../resources/fonts/RobotoCondensed/RobotoCondensed-Bold.svg#RobotoCondensed-Bold");
  font-weight: 700;
  font-style: normal; }

.space0-top, .space0, .space0-all {
  margin-top: 0px; }

.space0-bottom, .space0, .space0-all {
  margin-bottom: 0px; }

.space0-left, .space0-lat, .space0-all {
  margin-left: 0px; }

.space0-right, .space0-lat, .space0-all {
  margin-right: 0px; }

.padding0-top, .padding0, .padding0-all {
  padding-top: 0px; }

.padding0-bottom, .padding0, .padding0-all {
  padding-bottom: 0px; }

.padding0-left, .padding0-lat, .padding0-all {
  padding-left: 0px; }

.padding0-right, .padding0-lat, .padding0-all {
  padding-right: 0px; }

.space10-top, .space10, .space10-all {
  margin-top: 10px; }

.space10-bottom, .space10, .space10-all {
  margin-bottom: 10px; }

.space10-left, .space10-lat, .space10-all {
  margin-left: 10px; }

.space10-right, .space10-lat, .space10-all {
  margin-right: 10px; }

.padding10-top, .padding10, .padding10-all {
  padding-top: 10px; }

.padding10-bottom, .padding10, .padding10-all {
  padding-bottom: 10px; }

.padding10-left, .padding10-lat, .padding10-all {
  padding-left: 10px; }

.padding10-right, .padding10-lat, .padding10-all {
  padding-right: 10px; }

.space20-top, .space20, .space20-all {
  margin-top: 20px; }

.space20-bottom, .space20, .space20-all {
  margin-bottom: 20px; }

.space20-left, .space20-lat, .space20-all {
  margin-left: 20px; }

.space20-right, .space20-lat, .space20-all {
  margin-right: 20px; }

.padding20-top, .padding20, .padding20-all {
  padding-top: 20px; }

.padding20-bottom, .padding20, .padding20-all {
  padding-bottom: 20px; }

.padding20-left, .padding20-lat, .padding20-all {
  padding-left: 20px; }

.padding20-right, .padding20-lat, .padding20-all {
  padding-right: 20px; }

.space30-top, .space30, .space30-all {
  margin-top: 30px; }

.space30-bottom, .space30, .space30-all {
  margin-bottom: 30px; }

.space30-left, .space30-lat, .space30-all {
  margin-left: 30px; }

.space30-right, .space30-lat, .space30-all {
  margin-right: 30px; }

.padding30-top, .padding30, .padding30-all {
  padding-top: 30px; }

.padding30-bottom, .padding30, .padding30-all {
  padding-bottom: 30px; }

.padding30-left, .padding30-lat, .padding30-all {
  padding-left: 30px; }

.padding30-right, .padding30-lat, .padding30-all {
  padding-right: 30px; }

.space40-top, .space40, .space40-all {
  margin-top: 40px; }

.space40-bottom, .space40, .space40-all {
  margin-bottom: 40px; }

.space40-left, .space40-lat, .space40-all {
  margin-left: 40px; }

.space40-right, .space40-lat, .space40-all {
  margin-right: 40px; }

.padding40-top, .padding40, .padding40-all {
  padding-top: 40px; }

.padding40-bottom, .padding40, .padding40-all {
  padding-bottom: 40px; }

.padding40-left, .padding40-lat, .padding40-all {
  padding-left: 40px; }

.padding40-right, .padding40-lat, .padding40-all {
  padding-right: 40px; }

.space50-top, .space50, .space50-all {
  margin-top: 50px; }

.space50-bottom, .space50, .space50-all {
  margin-bottom: 50px; }

.space50-left, .space50-lat, .space50-all {
  margin-left: 50px; }

.space50-right, .space50-lat, .space50-all {
  margin-right: 50px; }

.padding50-top, .padding50, .padding50-all {
  padding-top: 50px; }

.padding50-bottom, .padding50, .padding50-all {
  padding-bottom: 50px; }

.padding50-left, .padding50-lat, .padding50-all {
  padding-left: 50px; }

.padding50-right, .padding50-lat, .padding50-all {
  padding-right: 50px; }

.space60-top, .space60, .space60-all {
  margin-top: 60px; }

.space60-bottom, .space60, .space60-all {
  margin-bottom: 60px; }

.space60-left, .space60-lat, .space60-all {
  margin-left: 60px; }

.space60-right, .space60-lat, .space60-all {
  margin-right: 60px; }

.padding60-top, .padding60, .padding60-all {
  padding-top: 60px; }

.padding60-bottom, .padding60, .padding60-all {
  padding-bottom: 60px; }

.padding60-left, .padding60-lat, .padding60-all {
  padding-left: 60px; }

.padding60-right, .padding60-lat, .padding60-all {
  padding-right: 60px; }

.space70-top, .space70, .space70-all {
  margin-top: 70px; }

.space70-bottom, .space70, .space70-all {
  margin-bottom: 70px; }

.space70-left, .space70-lat, .space70-all {
  margin-left: 70px; }

.space70-right, .space70-lat, .space70-all {
  margin-right: 70px; }

.padding70-top, .padding70, .padding70-all {
  padding-top: 70px; }

.padding70-bottom, .padding70, .padding70-all {
  padding-bottom: 70px; }

.padding70-left, .padding70-lat, .padding70-all {
  padding-left: 70px; }

.padding70-right, .padding70-lat, .padding70-all {
  padding-right: 70px; }

.space80-top, .space80, .space80-all {
  margin-top: 80px; }

.space80-bottom, .space80, .space80-all {
  margin-bottom: 80px; }

.space80-left, .space80-lat, .space80-all {
  margin-left: 80px; }

.space80-right, .space80-lat, .space80-all {
  margin-right: 80px; }

.padding80-top, .padding80, .padding80-all {
  padding-top: 80px; }

.padding80-bottom, .padding80, .padding80-all {
  padding-bottom: 80px; }

.padding80-left, .padding80-lat, .padding80-all {
  padding-left: 80px; }

.padding80-right, .padding80-lat, .padding80-all {
  padding-right: 80px; }

.space90-top, .space90, .space90-all {
  margin-top: 90px; }

.space90-bottom, .space90, .space90-all {
  margin-bottom: 90px; }

.space90-left, .space90-lat, .space90-all {
  margin-left: 90px; }

.space90-right, .space90-lat, .space90-all {
  margin-right: 90px; }

.padding90-top, .padding90, .padding90-all {
  padding-top: 90px; }

.padding90-bottom, .padding90, .padding90-all {
  padding-bottom: 90px; }

.padding90-left, .padding90-lat, .padding90-all {
  padding-left: 90px; }

.padding90-right, .padding90-lat, .padding90-all {
  padding-right: 90px; }

.space100-top, .space100, .space100-all {
  margin-top: 100px; }

.space100-bottom, .space100, .space100-all {
  margin-bottom: 100px; }

.space100-left, .space100-lat, .space100-all {
  margin-left: 100px; }

.space100-right, .space100-lat, .space100-all {
  margin-right: 100px; }

.padding100-top, .padding100, .padding100-all {
  padding-top: 100px; }

.padding100-bottom, .padding100, .padding100-all {
  padding-bottom: 100px; }

.padding100-left, .padding100-lat, .padding100-all {
  padding-left: 100px; }

.padding100-right, .padding100-lat, .padding100-all {
  padding-right: 100px; }

@font-face {
  font-family: 'fontello';
  src: url("../resources/fonts/Fontello/fontello.eot?99884753");
  src: url("../resources/fonts/Fontello/fontello.eot?99884753#iefix") format("embedded-opentype"), url("../resources/fonts/Fontello/fontello.woff2?99884753") format("woff2"), url("../resources/fonts/Fontello/fontello.woff?99884753") format("woff"), url("../resources/fonts/Fontello/fontello.ttf?99884753") format("truetype"), url("../resources/fonts/Fontello/fontello.svg?99884753#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../resources/fonts/Fontello/fontello.svg?34839915#fontello') format('svg');
  }
}
*/
[class^="icon-"]::before,
[class*=" icon-"]::before {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes */
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-add::before {
  content: '\e800'; }

/* '' */
.icon-alerts::before {
  content: '\e801'; }

/* '' */
.icon-cycles::before {
  content: '\e802'; }

/* '' */
.icon-common-file-warning::before {
  content: '\e803'; }

/* '' */
.icon-arrow-top::before {
  content: '\e804'; }

/* '' */
.icon-home::before {
  content: '\e805'; }

/* '' */
.icon-info::before {
  content: '\e806'; }

/* '' */
.icon-menu-hamburger::before {
  content: '\e807'; }

/* '' */
.icon-common-file-text-search::before {
  content: '\e808'; }

/* '' */
.icon-search::before {
  content: '\e809'; }

/* '' */
.icon-separator-right::before {
  content: '\e80a'; }

/* '' */
.icon-user::before {
  content: '\e80b'; }

/* '' */
.icon-promotional-comission::before {
  content: '\e80c'; }

/* '' */
.icon-server-error::before {
  content: '\e80d'; }

/* '' */
.icon-download::before {
  content: '\e80e'; }

/* '' */
.icon-arrow-bottom::before {
  content: '\e80f'; }

/* '' */
.icon-calendar::before {
  content: '\e810'; }

/* '' */
.icon-remove::before {
  content: '\e811'; }

/* '' */
.icon-filter::before {
  content: '\e812'; }

/* '' */
.icon-close::before {
  content: '\e813'; }

/* '' */
.icon-ok-check::before {
  content: '\e814'; }

/* '' */
.icon-edit::before {
  content: '\e815'; }

/* '' */
.icon-category::before {
  content: '\e816'; }

/* '' */
.icon-doc-warning::before {
  content: '\e817'; }

/* '' */
.icon-text-money-edit::before {
  content: '\e818'; }

/* '' */
.icon-checked::before {
  content: '\e819'; }

/* '' */
.icon-arrow-left::before {
  content: '\e81a'; }

/* '' */
.icon-arrow-right::before {
  content: '\e81b'; }

/* '' */
.icon-warning::before {
  content: '\e81c'; }

/* '' */
.icon-warning2::before {
  content: '\e81d'; }

/* '' */
.icon-text-money::before {
  content: '\e81e'; }

/* '' */
.icon-text-edit::before {
  content: '\e81f'; }

/* '' */
.icon-processing::before {
  content: '\e820'; }

/* '' */
.icon-file-code-settings::before {
  content: '\e821'; }

/* '' */
.icon-cloud-upload::before {
  content: '\e822'; }

/* '' */
.icon-light-on::before {
  content: '\e823'; }

/* '' */
.icon-upload::before {
  content: '\e824'; }

/* '' */
.icon-create-seller::before {
  content: '\e825'; }

/* '' */
