.seller-auto-complete {
  display: flex;
  margin-top: 8px; }
  .seller-auto-complete .input-search-partners {
    flex: 3;
    margin-right: 8px; }
  .seller-auto-complete .btn-add-partner {
    height: 51px;
    border-radius: 2px;
    background-color: #148cc7;
    color: #fff;
    font-weight: bold;
    font-family: "RobotoCondensed"; }

.step-partners-header {
  display: flex;
  border-bottom: 1px solid #d5d4d4;
  margin-bottom: 16px; }
  .step-partners-header #title {
    flex: 1;
    display: flex; }
    .step-partners-header #title .title {
      color: #148cc7;
      font-family: "RobotoCondensed";
      font-size: 16px; }
    .step-partners-header #title .counter {
      font-weight: bold;
      margin-left: 4px; }
  .step-partners-header .description {
    font-family: "RobotoCondensed";
    font-size: 10px;
    text-transform: uppercase; }

.search-seller label {
  text-transform: uppercase;
  font-size: 16px; }

.message-without-partners {
  margin: 64px auto 100px auto;
  text-align: center; }

.selected-partner-list {
  max-height: 200px;
  overflow: auto;
  margin-bottom: 44px; }
  .selected-partner-list .selected-partner-row {
    display: flex; }
  .selected-partner-list .selected-partner-odd {
    background-color: rgba(91, 120, 142, 0.04); }
  .selected-partner-list .wrap-partner-info {
    padding-right: 16px; }
  .selected-partner-list .selected-partner-item {
    width: 47.9%;
    padding: 8px 14px 8px 16px;
    position: relative; }
    .selected-partner-list .selected-partner-item .partner-ecommerce-id {
      font-weight: bold; }
    .selected-partner-list .selected-partner-item .partner-separator {
      font-weight: bold; }
    .selected-partner-list .selected-partner-item .icon-close {
      position: absolute;
      right: 8px;
      top: 8px;
      color: #d8232a; }
  .selected-partner-list .selected-partner-item:nth-child(odd) {
    margin-right: 24px; }
