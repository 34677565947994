.header-message-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10; }

.header-message {
  color: #fff;
  opacity: 0.98;
  padding: 34px 20px 34px 48px; }
  .header-message i {
    color: #fff; }
    .header-message i::before {
      position: relative;
      top: 1px; }

.header-success-message {
  background-color: #009161;
  font-family: "RobotoCondensed";
  font-size: 24px;
  font-stretch: condensed;
  font-style: italic; }
  .header-success-message .icon-ok-check {
    font-size: 26px;
    margin-right: 22px; }

.seller-account-filter {
  color: #156992;
  float: left;
  width: 30%; }

.seller-account-grid {
  width: 100%;
  font-family: "RobotoCondensed"; }
