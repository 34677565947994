div[role="document"] {
  overflow-y: initial; }

.dialog-content .expansion-panel {
  box-shadow: none;
  margin: 10px 0 15px 0; }
  .dialog-content .expansion-panel-summary {
    text-transform: uppercase;
    padding: 0; }

.massive-seller-account-insert-dialog label {
  color: color(slategray);
  font-weight: 500; }

.massive-seller-account-insert-dialog ol.expansion-panel-helper {
  list-style: none; }

.massive-seller-account-insert-dialog .expansion-panel-helper {
  max-height: 415px;
  overflow-y: scroll; }
  .massive-seller-account-insert-dialog .expansion-panel-helper-text {
    padding: 8px 8px 8px 17px;
    position: relative; }
    .massive-seller-account-insert-dialog .expansion-panel-helper-text .icon-arrow-right {
      font-size: 8px;
      left: 1px;
      position: absolute;
      top: 10px; }
  .massive-seller-account-insert-dialog .expansion-panel-helper .bold {
    font-weight: bold; }

.massive-seller-account-insert-dialog .icon-light-on {
  font-size: 16px;
  padding-right: 5px; }
