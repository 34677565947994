.tooltip {
  text-align: center;
  position: relative; }
  .tooltip .tooltip__container {
    visibility: hidden;
    position: absolute;
    z-index: 0;
    top: 150%;
    right: -28%;
    width: 260%;
    letter-spacing: normal;
    line-height: 1.64; }
    .tooltip .tooltip__container .tooltip__content {
      background: #f7f7f7;
      padding: 0.75rem 1rem; }
    .tooltip .tooltip__container .tooltip__content::before {
      display: block;
      position: absolute;
      z-index: -100;
      width: 100%;
      top: 0;
      bottom: 0;
      box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.15);
      right: 0; }
    .tooltip .tooltip__container .tooltip__tip {
      position: absolute;
      z-index: -100;
      width: 10px;
      height: 10px;
      background: #f7f7f7;
      left: 50%;
      top: -5px;
      transform: translateX(-50%) rotate(45deg);
      box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.15); }
  .tooltip:hover .tooltip__container {
    visibility: visible; }
