.closable {
  display: block;
  height: 100%;
  padding: 4px;
  position: relative;
  width: auto; }
  .closable .closable-close {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 17px;
    font-weight: bold;
    float: right;
    outline: none;
    padding: 0;
    position: absolute;
    top: 21px;
    right: 21px; }
