.audit-tr .negative {
  color: #d8232a; }

.audit-tr-download a {
  color: #71808b;
  font-size: 13px;
  letter-spacing: -0.6px;
  text-decoration: none; }

.audit-tr-description {
  font-size: 0.875em;
  width: 16.65%;
  position: relative;
  padding-right: 5px; }
  .audit-tr-description-icon {
    cursor: pointer;
    font-size: 1.3em; }

.wrap-audit-content {
  padding-top: 68px; }
  .wrap-audit-content .wrap-input-text {
    margin: 0; }
    .wrap-audit-content .wrap-input-text select {
      padding: 8px 8px; }
  .wrap-audit-content .header {
    position: relative; }
  .wrap-audit-content .title,
  .wrap-audit-content .description {
    position: absolute; }
  .wrap-audit-content .title {
    left: 0;
    top: -80px; }
  .wrap-audit-content .description {
    left: 210px;
    top: -72px; }

.wrap-audit-content #th_when,
.wrap-audit-content #th_operation,
.wrap-audit-content #th_details, .wrap-audit-content-period, .wrap-audit-content-action, .wrap-audit-content-return-commission, .wrap-audit-content-commission-balance, .wrap-audit-content-sales-details,
.audit-tr #th_when,
.audit-tr #th_operation,
.audit-tr #th_details,
.audit-tr-period,
.audit-tr-action,
.audit-tr-return-commission,
.audit-tr-commission-balance,
.audit-tr-sales-details {
  text-align: center; }

.wrap-audit-content-cycles,
.audit-tr-cycles {
  width: 28%; }

.wrap-audit-content-period,
.audit-tr-period {
  width: 15%; }

.wrap-audit-content-action,
.audit-tr-action {
  width: 12%; }

.wrap-audit-content-sales-details,
.audit-tr-sales-details {
  width: 25%; }
