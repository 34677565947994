.btn-default {
  background-color: rgba(198, 204, 209, 0.2);
  border: none;
  color: #5b788e;
  cursor: pointer;
  font-family: "RobotoCondensed";
  font-size: 1em;
  padding: 17px 18px 15px 16px;
  text-transform: uppercase; }
  .btn-default.disable {
    opacity: 0.3;
    cursor: not-allowed; }
    .btn-default.disable:hover {
      opacity: 0.6;
      background-color: rgba(198, 204, 209, 0.2); }
    .btn-default.disable:active {
      box-shadow: none; }
  .btn-default:hover {
    background-color: rgba(198, 204, 209, 0.4); }
  .btn-default:active {
    box-shadow: inset 0 0 18px rgba(0, 0, 0, 0.1); }
  .btn-default .text-description {
    padding: 0; }

.btn-action {
  background-color: #148cc7;
  color: #fff; }
  .btn-action.disable:hover {
    background-color: rgba(20, 140, 199, 0.5); }
  .btn-action:hover {
    background-color: #156992; }
  .btn-action:active {
    box-shadow: inset 0 0 18px rgba(0, 0, 0, 0.3); }

.btn-warning {
  background-color: #ff9d00;
  color: #fff; }
  .btn-warning.disable:hover {
    background-color: rgba(255, 157, 0, 0.5); }
  .btn-warning:hover {
    background-color: #e66700; }
  .btn-warning:active {
    box-shadow: inset 0 0 18px rgba(0, 0, 0, 0.3); }
