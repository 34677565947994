.report-grid {
  font-family: "RobotoCondensed";
  letter-spacing: normal; }
  .report-grid .header {
    font-size: 26px;
    font-weight: 300;
    letter-spacing: -0.6px;
    color: #156992;
    margin: 32px 0 9px;
    height: 25px;
    width: 100%; }
    .report-grid .header .title {
      display: inline-block; }
    .report-grid .header .description {
      font-size: 14px;
      vertical-align: middle;
      margin-left: 10px;
      display: inline-block;
      color: #71808b; }
    .report-grid .header .result-summary {
      font-size: 14px;
      padding-top: 9px;
      margin-right: 15px;
      float: right;
      color: #71808b;
      text-transform: uppercase; }
      .report-grid .header .result-summary .text-bold {
        font-weight: bold; }
