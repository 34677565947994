.plans-grid-header {
  float: right;
  margin-top: 31.5px;
  margin-bottom: 8px; }

.no-result-message {
  text-transform: uppercase; }

.table-plans-info {
  font-family: "RobotoCondensed";
  margin: 25px 24px 8px 24px;
  display: flex; }
  .table-plans-info-options {
    float: right;
    margin-left: 400px;
    text-align: right;
    text-transform: uppercase;
    margin-top: 10px; }
    .table-plans-info-options-separator {
      display: inline-block;
      color: #71808b;
      font-size: 2em;
      line-height: 8px;
      margin: 0 13px; }
    .table-plans-info-options-add {
      font-size: 0.875em;
      letter-spacing: -0.6px;
      margin-left: 3px;
      color: #2f9bd4;
      cursor: pointer;
      text-transform: uppercase; }
  .table-plans-info-summary {
    font-size: 0.875em;
    display: inline-block;
    color: #71808b;
    margin-top: 10px;
    text-transform: uppercase; }
    .table-plans-info-summary .text-bold {
      font-weight: bold; }
