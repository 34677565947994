.billing-adjustment-tr-cycle, .billing-adjustment-tr-period, .billing-adjustment-tr-type, .billing-adjustment-tr-id, .billing-adjustment-tr-created, .billing-adjustment-tr-seller, .billing-adjustment-tr-value, .billing-adjustment-tr-reason, .billing-adjustment-tr-description {
  font-family: "RobotoCondensed"; }

.billing-adjustment-tr-cycle {
  text-transform: uppercase;
  text-align: center;
  width: 6.27%; }
  .billing-adjustment-tr-cycle span {
    display: block; }
  .billing-adjustment-tr-cycle-id {
    font-size: 0.938em; }
  .billing-adjustment-tr-cycle-status {
    font-size: 0.625em; }

.billing-adjustment-tr-period {
  font-size: 0.875em;
  text-align: right;
  width: 8.15%; }
  .billing-adjustment-tr-period span {
    display: block; }

.billing-adjustment-tr-type {
  text-align: center;
  font-size: 0.938em;
  width: 6.27%; }

.billing-adjustment-tr-id {
  text-align: center;
  font-size: 0.938em;
  width: 7.52%; }

.billing-adjustment-tr-created {
  width: 10.03%;
  text-align: center; }
  .billing-adjustment-tr-created-date {
    display: block;
    font-size: 0.875em; }
  .billing-adjustment-tr-created-time {
    display: block;
    font-size: 0.75em; }

.billing-adjustment-tr-seller {
  font-weight: bold;
  font-size: 0.938em;
  width: 17.17%; }

.billing-adjustment-tr-value {
  font-weight: bold;
  font-size: 1em;
  width: 8.9%; }

.billing-adjustment-tr-reason {
  font-size: 0.938em;
  width: 17.54%; }

.billing-adjustment-tr-description {
  font-size: 0.875em;
  width: 16.65%;
  position: relative;
  padding-right: 5px; }
  .billing-adjustment-tr-description-icon {
    cursor: pointer;
    font-size: 1.3em;
    margin-top: 40px;
    position: relative;
    top: 30px;
    left: -10px; }
  .billing-adjustment-tr-description-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: right;
    top: 0; }
    .billing-adjustment-tr-description-overlay.hide {
      display: none;
      background-color: none; }
    .billing-adjustment-tr-description-overlay.show {
      left: 0;
      display: block;
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, white 90%, white 100%);
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, white 90%, white 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, white 90%, white 100%);
      filter: progid:dximagetransform.microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); }

.billing-adjustment-tr td span {
  padding: 20px 0;
  height: 400px; }

.th-content {
  text-align: center; }

.no-result-message {
  color: #71808b;
  font-family: "RobotoCondensed";
  font-size: 1em;
  letter-spacing: -0.6px;
  text-transform: none; }
  .no-result-message i {
    font-size: 1.125em; }

.table-info {
  font-family: "RobotoCondensed";
  margin: 25px 24px 8px 24px;
  display: flex; }
  .table-info-title {
    margin-right: auto; }
    .table-info-title-main {
      color: #156992;
      font-size: 1.625em;
      float: left; }
    .table-info-title-subtitle {
      color: #71808b;
      font-size: 0.875em;
      float: left;
      margin-left: 6px;
      margin-top: 10px; }
  .table-info-options {
    font-size: 0.875em;
    float: left;
    margin-left: 400px;
    text-align: right;
    text-transform: uppercase;
    margin-top: 10px; }
    .table-info-options-add, .table-info-options-download, .table-info-options-separator {
      float: left; }
    .table-info-options-separator {
      color: #71808b;
      font-size: 2em;
      line-height: 8px;
      margin: 0 13px; }
    .table-info-options-add, .table-info-options-download {
      letter-spacing: -0.6px;
      margin-left: 3px; }
    .table-info-options-add {
      color: #2f9bd4;
      cursor: pointer; }
    .table-info-options-download a {
      color: #71808b;
      text-decoration: none; }
  .table-info-summary {
    color: #71808b;
    font-size: 0.875em;
    float: right;
    margin-top: 10px;
    text-transform: uppercase; }
    .table-info-summary .text-bold {
      font-weight: bold; }
