.payment-report-summary {
  font-family: "RobotoCondensed"; }
  .payment-report-summary .grid_message_area {
    height: 100%;
    position: relative;
    background-color: rgba(91, 120, 142, 0.6); }
    .payment-report-summary .grid_message_area .grid_message_box {
      position: absolute;
      height: 80px;
      width: 410px;
      background-color: white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center; }
      .payment-report-summary .grid_message_area .grid_message_box p {
        text-align: center;
        align-self: center;
        text-transform: uppercase;
        color: #156992; }
  .payment-report-summary .header {
    color: #156992;
    margin: 32px 0 9px 0;
    height: 25px;
    font-size: 26px;
    letter-spacing: -0.6px;
    font-weight: 300; }
    .payment-report-summary .header .title {
      float: left;
      font-weight: 300;
      font-size: 26px; }
    .payment-report-summary .header .description {
      font-size: 14px;
      margin-left: 10px;
      vertical-align: middle;
      display: inline-block;
      color: #71808b; }
  .payment-report-summary .error {
    text-align: center;
    line-height: 115px;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #fbfcfc;
    color: #464e54; }
  .payment-report-summary .resume {
    border: 1px solid #d9d9d9;
    height: 120px;
    width: 100%; }
    .payment-report-summary .resume .label-box {
      background-color: #f7f7f7;
      float: left;
      width: 13%;
      min-width: 153px;
      max-width: 165px;
      height: 100%;
      font-size: 18px;
      color: #5b788e; }
      .payment-report-summary .resume .label-box .title {
        float: left;
        height: 100%;
        text-align: right;
        vertical-align: middle;
        padding-top: 24px;
        margin-left: 10%;
        margin-right: 10%;
        width: 80%;
        line-height: 24px;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: normal; }
    .payment-report-summary .resume .summary-box {
      float: left;
      height: 100%;
      width: 86%; }
      .payment-report-summary .resume .summary-box .content-box {
        margin: 37px 0 0 0; }
        .payment-report-summary .resume .summary-box .content-box .total {
          margin: 0 auto; }
      .payment-report-summary .resume .summary-box .total-value,
      .payment-report-summary .resume .summary-box .total-payment,
      .payment-report-summary .resume .summary-box .total-commission {
        border-left: solid #d9d9d9;
        border-width: 0 1px; }
        .payment-report-summary .resume .summary-box .total-value .label,
        .payment-report-summary .resume .summary-box .total-payment .label,
        .payment-report-summary .resume .summary-box .total-commission .label {
          display: flex; }
        .payment-report-summary .resume .summary-box .total-value .info,
        .payment-report-summary .resume .summary-box .total-payment .info,
        .payment-report-summary .resume .summary-box .total-commission .info {
          margin-left: 5px; }
        .payment-report-summary .resume .summary-box .total-value .tooltip__content,
        .payment-report-summary .resume .summary-box .total-payment .tooltip__content,
        .payment-report-summary .resume .summary-box .total-commission .tooltip__content {
          color: #156992;
          font-size: 14px; }
          .payment-report-summary .resume .summary-box .total-value .tooltip__content .operation,
          .payment-report-summary .resume .summary-box .total-payment .tooltip__content .operation,
          .payment-report-summary .resume .summary-box .total-commission .tooltip__content .operation {
            font-weight: 100; }
      .payment-report-summary .resume .summary-box .total-value .tooltip__container {
        width: 240px;
        right: -108px; }
      .payment-report-summary .resume .summary-box .total-commission {
        width: 25%; }
        .payment-report-summary .resume .summary-box .total-commission .label .tooltip__container {
          width: 410px;
          right: -193px; }
      .payment-report-summary .resume .summary-box .total-payment .label .tooltip__container {
        width: 280px;
        right: -128px; }
      .payment-report-summary .resume .summary-box .average-commission,
      .payment-report-summary .resume .summary-box .total-value,
      .payment-report-summary .resume .summary-box .total-payment {
        width: 20%; }
      .payment-report-summary .resume .summary-box .average-commission,
      .payment-report-summary .resume .summary-box .total-commission,
      .payment-report-summary .resume .summary-box .total-value,
      .payment-report-summary .resume .summary-box .total-payment {
        float: left;
        display: flex; }
        .payment-report-summary .resume .summary-box .average-commission .label,
        .payment-report-summary .resume .summary-box .total-commission .label,
        .payment-report-summary .resume .summary-box .total-value .label,
        .payment-report-summary .resume .summary-box .total-payment .label {
          text-transform: uppercase;
          font-size: 16px;
          color: #71808b; }
        .payment-report-summary .resume .summary-box .average-commission .value,
        .payment-report-summary .resume .summary-box .total-commission .value,
        .payment-report-summary .resume .summary-box .total-value .value,
        .payment-report-summary .resume .summary-box .total-payment .value {
          font-size: 26px;
          letter-spacing: -0.6px;
          color: #156992;
          margin-bottom: 5px;
          display: block; }
      .payment-report-summary .resume .summary-box .download-reports {
        font-size: 14px;
        color: #71808b;
        display: inline-block;
        width: 11%; }
        .payment-report-summary .resume .summary-box .download-reports a {
          text-decoration: none;
          display: block; }
        .payment-report-summary .resume .summary-box .download-reports .download .tooltip__container {
          width: 280px;
          right: -50px; }
          .payment-report-summary .resume .summary-box .download-reports .download .tooltip__container .tooltip__content {
            display: flex;
            width: 100%; }
            .payment-report-summary .resume .summary-box .download-reports .download .tooltip__container .tooltip__content .tooltip__insight-icon {
              display: inline-flex;
              width: 56px; }
            .payment-report-summary .resume .summary-box .download-reports .download .tooltip__container .tooltip__content span {
              display: inline-flex;
              text-align: left;
              margin-left: 10px; }
        .payment-report-summary .resume .summary-box .download-reports .report-link {
          background: none;
          border: none;
          color: inherit;
          padding-left: 12px;
          text-align: left;
          margin-bottom: 5px;
          font: inherit;
          line-height: 23px;
          cursor: pointer;
          outline: none; }
          .payment-report-summary .resume .summary-box .download-reports .report-link.disable {
            opacity: 0.3;
            cursor: default; }
