.partner-wizard-info {
  display: flex;
  padding: 16px 0 32px 0;
  font-family: font(RobotoCondensed, family-name); }
  .partner-wizard-info-selected {
    margin-right: auto;
    font-size: 18px;
    font-stretch: condensed; }
    .partner-wizard-info-selected-title {
      color: color(slategray);
      font-weight: 400 !important;
      text-transform: uppercase; }
    .partner-wizard-info-selected-content {
      color: color(oxfordblue);
      font-weight: 600 !important;
      margin-top: 8px; }
  .partner-wizard-info-warning {
    width: 227px;
    display: flex;
    padding-top: 8px; }
    .partner-wizard-info-warning-content {
      font-size: 10px;
      font-stretch: condensed;
      text-transform: uppercase; }
