div[role='document'] {
  overflow-y: initial; }

.dialog-content .expansion-panel {
  box-shadow: none;
  margin: 10px 0 15px 0; }
  .dialog-content .expansion-panel-summary {
    text-transform: uppercase;
    padding: 0; }

.massive-promotion-insert-dialog label {
  color: #71808b;
  font-weight: 500; }

.massive-promotion-insert-dialog #start_date,
.massive-promotion-insert-dialog #end_date {
  color: transparent;
  cursor: default;
  padding-left: 50px;
  text-shadow: 0 0 0 #464e54; }
  .massive-promotion-insert-dialog #start_date:focus,
  .massive-promotion-insert-dialog #end_date:focus {
    outline: none; }

.massive-promotion-insert-dialog ol.expansion-panel-helper {
  list-style: none; }

.massive-promotion-insert-dialog .expansion-panel-helper {
  max-height: 415px;
  overflow-y: scroll; }
  .massive-promotion-insert-dialog .expansion-panel-helper-text {
    padding: 8px 8px 8px 17px;
    position: relative; }
    .massive-promotion-insert-dialog .expansion-panel-helper-text .icon-arrow-right {
      font-size: 8px;
      left: 1px;
      position: absolute;
      top: 10px; }
  .massive-promotion-insert-dialog .expansion-panel-helper .bold {
    font-weight: bold; }

.massive-promotion-insert-dialog .icon-light-on {
  font-size: 16px;
  padding-right: 5px; }

.massive-promotion-insert-dialog .react-datepicker {
  border: none; }
  .massive-promotion-insert-dialog .react-datepicker-popper {
    box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.19);
    text-transform: uppercase; }
  .massive-promotion-insert-dialog .react-datepicker__header {
    color: #fff;
    border: none;
    background-color: #fff; }
  .massive-promotion-insert-dialog .react-datepicker__triangle {
    display: none; }
  .massive-promotion-insert-dialog .react-datepicker__time-container {
    border-color: #dcdbdc; }
  .massive-promotion-insert-dialog .react-datepicker__navigation--next {
    border-left-color: #2f9bd4; }
  .massive-promotion-insert-dialog .react-datepicker__navigation--previous {
    border-right-color: #2f9bd4; }
  .massive-promotion-insert-dialog .react-datepicker__day, .massive-promotion-insert-dialog .react-datepicker__time, .massive-promotion-insert-dialog .react-datepicker__day-name, .massive-promotion-insert-dialog .react-datepicker__time-name, .massive-promotion-insert-dialog .react-datepicker__month-text {
    color: #464e54;
    font-family: "RobotoCondensed"; }
  .massive-promotion-insert-dialog .react-datepicker__day, .massive-promotion-insert-dialog .react-datepicker__day-name, .massive-promotion-insert-dialog .react-datepicker__time-name {
    line-height: 1.7rem; }
  .massive-promotion-insert-dialog .react-datepicker__day-name, .massive-promotion-insert-dialog .react-datepicker__day-names {
    font-weight: bold; }
  .massive-promotion-insert-dialog .react-datepicker__week, .massive-promotion-insert-dialog .react-datepicker__day-names {
    margin: 2px 0; }
  .massive-promotion-insert-dialog .react-datepicker-year-header, .massive-promotion-insert-dialog .react-datepicker-time__header, .massive-promotion-insert-dialog .react-datepicker__current-month {
    color: #2f9bd4;
    font-family: "RobotoCondensed";
    font-weight: normal;
    text-transform: uppercase; }
  .massive-promotion-insert-dialog .react-datepicker__time-list-item--selected {
    background-color: #2f9bd4 !important; }
  .massive-promotion-insert-dialog .react-datepicker__day--today {
    background-color: #dcdbdc;
    border-radius: 50%; }
  .massive-promotion-insert-dialog .react-datepicker__day--selected, .massive-promotion-insert-dialog .react-datepicker__day--keyboard-selected {
    border-radius: 50%;
    background-color: #2f9bd4;
    color: #fff;
    font-weight: normal; }
