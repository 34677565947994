.promotions .seller-filter-auto-complete {
  margin: 0; }
  .promotions .seller-filter-auto-complete i {
    color: #156992; }
  .promotions .seller-filter-auto-complete input {
    vertical-align: bottom; }

.promotion-grid__filter-area {
  width: 30%;
  float: left; }

.promotions-table .promotion.th-seller {
  width: 224px; }

.promotions-table .promotion.th-promotional-commission {
  text-align: center;
  width: 21%; }

.promotions-table .promotion.th-validity {
  text-align: center;
  width: 7%; }

.promotions-table .promotion.th-status {
  text-align: center;
  width: 120px; }

.promotions-table .promotion.th-campaign {
  width: 15%; }

.promotions-table .promotion.th-edit-at {
  text-align: center;
  width: 128px; }

.promotions-table .promotion.th-edit-actions {
  width: 114px; }

@media only screen and (min-width: 1900px) {
  .promotions-table .promotion.th-promotional-commission {
    text-align: center;
    width: 15%; }
  .promotions-table .promotion.th-campaign {
    width: 7%; } }

.promotions-table .promotion-tr {
  color: #464e54;
  font-size: 12px; }
  .promotions-table .promotion-tr-partner-opacity, .promotions-table .promotion-tr-rules-opacity, .promotions-table .promotion-tr-validity-opacity, .promotions-table .promotion-tr-status-opacity, .promotions-table .promotion-tr-campaign-opacity, .promotions-table .promotion-tr-edit-at-opacity {
    opacity: 0.3; }
  .promotions-table .promotion-tr-switch-opacity .switch-option {
    opacity: 0.3; }
  .promotions-table .promotion-tr-partner {
    font-size: 13px;
    font-weight: 600; }
  .promotions-table .promotion-tr-rules, .promotions-table .promotion-tr-rules-opacity {
    position: relative; }
    .promotions-table .promotion-tr-rules .details-list, .promotions-table .promotion-tr-rules-opacity .details-list {
      display: inline-block;
      list-style: none;
      margin-left: 36px; }
    .promotions-table .promotion-tr-rules .promotion-type, .promotions-table .promotion-tr-rules-opacity .promotion-type {
      border-radius: 100%;
      color: #156992;
      float: left;
      font-size: 10px;
      margin: 0 10px auto 0;
      padding: 4px 4px 2px; }
      .promotions-table .promotion-tr-rules .promotion-type + .grouped-list.details-list, .promotions-table .promotion-tr-rules-opacity .promotion-type + .grouped-list.details-list {
        margin-left: 0; }
    .promotions-table .promotion-tr-rules .sku, .promotions-table .promotion-tr-rules-opacity .sku {
      background-color: rgba(47, 155, 212, 0.15); }
    .promotions-table .promotion-tr-rules .category, .promotions-table .promotion-tr-rules-opacity .category {
      background-color: rgba(113, 128, 139, 0.15); }
  .promotions-table .promotion-tr-status, .promotions-table .promotion-tr-status-dimmed {
    font-weight: normal;
    text-align: center;
    text-transform: uppercase; }
    .promotions-table .promotion-tr-status-dimmed, .promotions-table .promotion-tr-status-dimmed-dimmed {
      opacity: 0.3; }
  .promotions-table .promotion-tr-changed-by {
    font-style: italic; }
  .promotions-table .promotion-tr-edit-at, .promotions-table .promotion-tr-edit-at-opacity {
    text-align: center; }

.promotions-table .promotion-options {
  display: flex;
  align-items: center; }
  .promotions-table .promotion-options .download-promotion {
    cursor: pointer;
    font-size: 22px; }
    .promotions-table .promotion-options .download-promotion:hover {
      color: #1d2023; }
