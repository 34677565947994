.col-sku {
  width: 60%; }
  .col-sku div {
    margin-right: 8px; }

.col-sku-commission {
  width: 40%; }
  .col-sku-commission .input-sku-commission {
    display: inline-block;
    width: 40%;
    margin-right: 8px; }
    .col-sku-commission .input-sku-commission .input-box {
      height: auto; }

.btn-add-sku-commission i {
  position: inherit;
  left: auto;
  right: auto; }

.sku-commission-add-container {
  margin-bottom: auto; }

.step-sku-and-commission-list-header {
  display: flex;
  border-bottom: 1px solid #d5d4d4;
  margin-bottom: 16px; }
  .step-sku-and-commission-list-header #title {
    flex: 1;
    display: flex; }
    .step-sku-and-commission-list-header #title .title {
      color: #148cc7;
      font-family: "RobotoCondensed";
      font-size: 16px; }
    .step-sku-and-commission-list-header #title .counter {
      font-weight: bold;
      margin-left: 4px; }

.message-without-sku-and-commission-list {
  margin-top: 20px;
  text-align: center; }

.selected-sku-and-commission-list {
  max-height: 200px;
  overflow: auto;
  margin-bottom: 44px; }
  .selected-sku-and-commission-list .selected-sku-and-commission-row {
    display: flex; }
  .selected-sku-and-commission-list .selected-sku-and-commission-odd {
    background-color: rgba(91, 120, 142, 0.04); }
  .selected-sku-and-commission-list .selected-sku-and-commission-item {
    width: 47.9%;
    padding: 8px 14px 8px 16px;
    position: relative; }
    .selected-sku-and-commission-list .selected-sku-and-commission-item .sku {
      display: inline-block;
      text-transform: uppercase;
      margin-right: 10px;
      max-width: 125px;
      word-break: break-all; }
    .selected-sku-and-commission-list .selected-sku-and-commission-item .commission {
      padding: 6px 0px;
      background-color: #ebebeb;
      font-size: 14px;
      color: #3b4e5c;
      float: right;
      width: 60px;
      text-align: center;
      margin-right: 12px;
      border-radius: 2px; }
    .selected-sku-and-commission-list .selected-sku-and-commission-item .icon-close {
      position: absolute;
      right: 8px;
      top: 14px;
      color: #d8232a; }
    .selected-sku-and-commission-list .selected-sku-and-commission-item .info {
      padding-right: 16px;
      line-height: 28px; }

.selected-sku-and-commission-item:nth-child(odd) {
  margin-right: 24px; }
