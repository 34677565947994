.promotions-grid-header {
  float: right;
  margin-top: 46px;
  margin-bottom: 8px;
  display: flex; }

.no-result-message {
  text-transform: uppercase; }

.table-promotions-info-options {
  margin-left: 400px;
  text-align: right;
  text-transform: uppercase;
  margin-top: 10px;
  display: flex; }
  .table-promotions-info-options-separator {
    display: inline-block;
    color: #71808b;
    font-size: 1.5em;
    line-height: 10px;
    margin: 0 7px; }
  .table-promotions-info-options-add, .table-promotions-info-options-send-file {
    color: #2f9bd4;
    cursor: pointer;
    font-family: "RobotoCondensed";
    font-size: 0.875em;
    margin-left: 3px;
    text-transform: uppercase; }

.table-promotions-info-summary {
  font-family: "RobotoCondensed";
  font-size: 0.875em;
  display: inline-block;
  color: #71808b;
  margin-top: 10px;
  text-transform: uppercase; }
  .table-promotions-info-summary .text-bold {
    font-weight: bold; }
