.commission-management-report-summary {
  font-family: "RobotoCondensed"; }
  .commission-management-report-summary .grid_message_area {
    background-color: rgba(91, 120, 142, 0.6);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%; }
    .commission-management-report-summary .grid_message_area .grid_message_box {
      position: absolute;
      height: 80px;
      width: 410px;
      background-color: white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center; }
      .commission-management-report-summary .grid_message_area .grid_message_box p {
        text-align: center;
        align-self: center;
        text-transform: uppercase;
        color: #156992; }
  .commission-management-report-summary .header {
    color: #156992;
    margin: 32px 0 9px 0;
    height: 25px;
    font-size: 26px;
    letter-spacing: -0.6px;
    font-weight: 300; }
    .commission-management-report-summary .header .title {
      float: left;
      font-weight: 300;
      font-size: 26px; }
    .commission-management-report-summary .header .description {
      font-size: 14px;
      margin-left: 10px;
      vertical-align: middle;
      display: inline-block;
      color: #71808b; }
  .commission-management-report-summary .error {
    text-align: center;
    line-height: 115px;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #fbfcfc;
    color: #464e54; }
  .commission-management-report-summary .resume {
    border: 1px solid #d9d9d9;
    position: relative; }
    .commission-management-report-summary .resume .content-box {
      display: table; }
      .commission-management-report-summary .resume .content-box div {
        display: table-cell; }
      .commission-management-report-summary .resume .content-box .average-commission {
        width: 13.6%; }
    .commission-management-report-summary .resume .label-box {
      background-color: #f7f7f7;
      color: #5b788e; }
      .commission-management-report-summary .resume .label-box .title {
        text-align: right;
        padding: 28px 20px 20px;
        margin-right: 16px;
        line-height: 24px;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: normal;
        width: 140px; }
    .commission-management-report-summary .resume .summary-box::after {
      content: " ";
      display: table;
      clear: both; }
    .commission-management-report-summary .resume .summary-box .content-box .total {
      border-left: solid #d9d9d9;
      border-width: 0 1px;
      padding-left: 20px; }
    .commission-management-report-summary .resume .summary-box .content-box .average-commission .total {
      border: none; }
    .commission-management-report-summary .resume .summary-box .total-value .label,
    .commission-management-report-summary .resume .summary-box .sales-commission .label,
    .commission-management-report-summary .resume .summary-box .total-commission .label,
    .commission-management-report-summary .resume .summary-box .return-commission .label {
      display: flex; }
    .commission-management-report-summary .resume .summary-box .total-value .info,
    .commission-management-report-summary .resume .summary-box .sales-commission .info,
    .commission-management-report-summary .resume .summary-box .total-commission .info,
    .commission-management-report-summary .resume .summary-box .return-commission .info {
      margin-left: 5px; }
    .commission-management-report-summary .resume .summary-box .total-value .tooltip__content,
    .commission-management-report-summary .resume .summary-box .sales-commission .tooltip__content,
    .commission-management-report-summary .resume .summary-box .total-commission .tooltip__content,
    .commission-management-report-summary .resume .summary-box .return-commission .tooltip__content {
      color: #156992;
      font-size: 14px; }
      .commission-management-report-summary .resume .summary-box .total-value .tooltip__content .operation,
      .commission-management-report-summary .resume .summary-box .sales-commission .tooltip__content .operation,
      .commission-management-report-summary .resume .summary-box .total-commission .tooltip__content .operation,
      .commission-management-report-summary .resume .summary-box .return-commission .tooltip__content .operation {
        font-weight: 100; }
    .commission-management-report-summary .resume .summary-box .total-value .tooltip__container {
      width: 240px;
      right: -108px; }
    .commission-management-report-summary .resume .summary-box .total-commission .label .tooltip__container {
      width: 410px;
      right: -193px; }
    .commission-management-report-summary .resume .summary-box .sales-commission .label .tooltip__container {
      width: 280px;
      right: -128px; }
    .commission-management-report-summary .resume .summary-box .average-commission,
    .commission-management-report-summary .resume .summary-box .total-value,
    .commission-management-report-summary .resume .summary-box .sales-commission,
    .commission-management-report-summary .resume .summary-box .return-commission,
    .commission-management-report-summary .resume .summary-box .total-commission {
      padding-right: 20px;
      vertical-align: middle; }
      .commission-management-report-summary .resume .summary-box .average-commission .label,
      .commission-management-report-summary .resume .summary-box .total-value .label,
      .commission-management-report-summary .resume .summary-box .sales-commission .label,
      .commission-management-report-summary .resume .summary-box .return-commission .label,
      .commission-management-report-summary .resume .summary-box .total-commission .label {
        text-transform: uppercase;
        font-size: 16px;
        color: #71808b; }
      .commission-management-report-summary .resume .summary-box .average-commission .value,
      .commission-management-report-summary .resume .summary-box .total-value .value,
      .commission-management-report-summary .resume .summary-box .sales-commission .value,
      .commission-management-report-summary .resume .summary-box .return-commission .value,
      .commission-management-report-summary .resume .summary-box .total-commission .value {
        font-size: 26px;
        letter-spacing: -0.6px;
        color: #156992;
        margin-bottom: 5px;
        display: block; }
    .commission-management-report-summary .resume .summary-box .download-reports {
      color: #71808b;
      font-size: 14px; }
      .commission-management-report-summary .resume .summary-box .download-reports a {
        text-decoration: none;
        display: block; }
      .commission-management-report-summary .resume .summary-box .download-reports .report-link {
        background: none;
        border: none;
        color: inherit;
        text-align: left;
        margin-bottom: 5px;
        font: inherit;
        line-height: 23px;
        cursor: pointer;
        outline: none; }
        .commission-management-report-summary .resume .summary-box .download-reports .report-link.disable {
          opacity: 0.3;
          cursor: default; }
    .commission-management-report-summary .resume::after {
      content: " ";
      display: table;
      clear: both; }
