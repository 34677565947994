.sellers-grid-header {
  float: right;
  margin-top: 52px; }

.no-result-message {
  text-transform: uppercase; }

.table-sellers-info {
  font-family: "RobotoCondensed";
  margin: 25px 24px 8px 24px;
  display: flex; }
  .table-sellers-info-summary {
    font-size: 0.875em;
    display: inline-block;
    color: #71808b;
    margin-top: 10px;
    text-transform: uppercase; }
    .table-sellers-info-summary .text-bold {
      font-weight: bold; }
