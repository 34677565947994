.audit-description-dialog-title {
  width: 56px;
  display: inline-block; }

.key-value-table-container {
  height: 200px;
  overflow: auto;
  border: 0px; }
  .key-value-table-container .key-value-table {
    width: 100%;
    border-spacing: 0px; }
    .key-value-table-container .key-value-table tr:nth-child(odd) {
      background: #eaeaea; }
    .key-value-table-container .key-value-table .left-column {
      width: 50%;
      padding: 10px;
      font-weight: bold; }
    .key-value-table-container .key-value-table .right-column {
      height: 18px; }
