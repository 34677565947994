.page-title__title {
  font-family: "RobotoCondensed";
  font-size: 1.625em;
  font-weight: 300;
  font-style: normal;
  letter-spacing: -0.6px;
  color: #156992;
  display: inline-block;
  margin-bottom: 16px;
  margin-top: 20px; }

.page-title__title-description {
  font-family: "RobotoCondensed";
  font-size: 0.875em;
  vertical-align: middle;
  display: inline-block;
  color: #71808b;
  padding-bottom: 5px;
  margin-left: 10px; }
