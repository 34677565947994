.warning-dialog {
  padding: 0; }
  .warning-dialog-message {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.5;
    letter-spacing: normal;
    color: #71808b; }
  .warning-dialog-header {
    background-color: #ff9d00;
    padding: 24px 32px 16px 32px; }
    .warning-dialog-header .icon-close {
      color: #fff; }
    .warning-dialog-header .icon {
      font-size: 22px;
      color: #fff; }
    .warning-dialog-header .dialog-header-title {
      color: #fff;
      text-transform: uppercase; }
  .warning-dialog .dialog-content {
    padding: 0 32px; }
  .warning-dialog-footer {
    padding: 32px; }
