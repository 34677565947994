.error-dialog-header {
  padding: 24px 20px 16px 20px; }

.dialog-header-title {
  font-size: 24px; }

#errors-lines {
  margin-top: 32px; }

.error-title,
.error-text {
  font-size: 0.875em; }

.error-title {
  color: #c20000;
  font-weight: bold;
  margin-top: 16px; }

.error-text {
  margin-top: 16px; }
  .error-text strong {
    font-weight: bold; }

.dialog-content .error-expansion-panel {
  box-shadow: none;
  margin: 0;
  padding: 6px 0;
  font-size: 0.875em; }
  .dialog-content .error-expansion-panel::before {
    background-color: #fff; }
  .dialog-content .error-expansion-panel strong {
    font-weight: bold; }
  .dialog-content .error-expansion-panel-summary {
    min-height: 0 !important;
    padding: 8px 8px 0 0;
    text-transform: none !important; }
    .dialog-content .error-expansion-panel-summary div {
      margin: 0; }
  .dialog-content .error-expansion-panel-detail {
    padding: 8px 0 0 16px; }
