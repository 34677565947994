.error-message {
  background-color: #f90;
  border-radius: 10px;
  bottom: 112px;
  color: #fff;
  display: inline-block;
  font-family: "RobotoCondensed";
  font-size: 18px;
  padding: 20px 15.5px 20px 35px;
  position: absolute;
  z-index: 100; }
  .error-message .container {
    display: flex; }
    .error-message .container .message {
      padding-top: 10px; }
    .error-message .container .icon-server-error {
      font-size: 28px;
      padding-top: 2px;
      padding-right: 10px; }
    .error-message .container .icon-close {
      cursor: pointer;
      font-size: 28px;
      align-self: center;
      padding: 4px 0 0 15px; }

.hide-error {
  left: -500px; }

.show-error {
  animation: show-error 0.5s linear 1;
  left: -5px; }

@keyframes show-error {
  from {
    left: -500px; }
  to {
    left: -5px; } }

@keyframes hide-error {
  from {
    left: -5px; }
  to {
    left: -500px; } }
