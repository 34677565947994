.user .user-container {
  margin: 20px 25px 0 5px;
  font-family: "RobotoCondensed";
  display: flex;
  align-items: center; }
  .user .user-container .icon-user {
    background-color: #f7f7f7;
    color: #2f9bd4;
    display: inline-block;
    border-radius: 50%;
    padding: 15px; }
  .user .user-container .user-admin {
    padding-left: 8px;
    font-weight: 500;
    color: #2f9bd4; }
  .user .user-container .user-name,
  .user .user-container .user-admin {
    font-size: 16px; }
  .user .user-container .user-name {
    padding-left: 4px;
    color: #156992;
    font-weight: 300; }
