.massive-promotion-error-print {
  width: 800px;
  padding-top: 50px;
  margin: 0 auto;
  font-family: "RobotoCondensed";
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal; }

.massive-promotion-error-print-header {
  text-transform: uppercase;
  letter-spacing: -1px;
  color: #812812; }
  .massive-promotion-error-print-header h2 {
    font-weight: 300; }
    .massive-promotion-error-print-header h2 i {
      margin-right: 5px; }

.massive-promotion-error-print-content {
  padding-left: 40px; }
  .massive-promotion-error-print-content #errors-lines {
    margin-top: 0; }
  .massive-promotion-error-print-content .error-expansion-panel {
    box-shadow: none;
    margin: 0 !important;
    background-color: inherit; }
  .massive-promotion-error-print-content .error-expansion-panel-summary {
    margin: 5px 0;
    min-height: 0 !important;
    opacity: 1 !important; }
    .massive-promotion-error-print-content .error-expansion-panel-summary > div {
      margin: 0 !important; }
  .massive-promotion-error-print-content .error-expansion-panel-detail {
    padding: 0 0 0 36px; }
