/* stylelint disable- */
@keyframes bounce_progress {
  0% {
    margin-left: -250px; }
  100% {
    margin-left: 250px; } }

@-o-keyframes bounce_progress {
  0% {
    margin-left: -250px; }
  100% {
    margin-left: 250px; } }

@-ms-keyframes bounce_progress {
  0% {
    margin-left: -250px; }
  100% {
    margin-left: 250px; } }

@-webkit-keyframes bounce_progress {
  0% {
    margin-left: -250px; }
  100% {
    margin-left: 250px; } }

@-moz-keyframes bounce_progress {
  0% {
    margin-left: -250px; }
  100% {
    margin-left: 250px; } }

.loading {
  background-color: rgba(91, 120, 142, 0.6);
  font-family: "RobotoCondensed";
  text-align: center;
  line-height: 115px;
  font-size: 17px;
  text-transform: uppercase; }
  .loading-bar {
    width: 250px;
    height: 3px;
    overflow: hidden;
    background-color: #005091;
    margin: auto;
    position: relative;
    top: -43px;
    left: 13px; }
    .loading-bar .progress {
      background-color: #d8232a;
      margin-top: 0;
      margin-left: -250px;
      width: 100px;
      height: 3px;
      animation-name: bounce_progress;
      -o-animation-name: bounce_progress;
      -ms-animation-name: bounce_progress;
      -webkit-animation-name: bounce_progress;
      -moz-animation-name: bounce_progress;
      animation-duration: 1.5s;
      -o-animation-duration: 1.5s;
      -ms-animation-duration: 1.5s;
      -webkit-animation-duration: 1.5s;
      -moz-animation-duration: 1.5s;
      animation-iteration-count: infinite;
      -o-animation-iteration-count: infinite;
      -ms-animation-iteration-count: infinite;
      -webkit-animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
      animation-timing-function: linear;
      -o-animation-timing-function: linear;
      -ms-animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear; }
