.dialog-content {
  text-align: inherit; }

.col-2 {
  width: 42%; }

.pull-left {
  float: left;
  font-weight: normal; }

.pull-right {
  float: right;
  font-weight: normal; }

.text-bold,
#cycle-instance {
  font-weight: bold; }

.billing-adjustment-description > label {
  width: 50%; }

.form-description {
  font-family: "RobotoCondensed";
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #5b788e; }

.form-group {
  width: 100%;
  clear: both; }

.form-info {
  margin-top: 16px;
  margin-bottom: 24px;
  opacity: 0.5;
  border-radius: 2px;
  background-color: #ebebeb;
  padding: 18px 24px 17px 24px;
  font-family: "RobotoCondensed";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #71808b; }
  .form-info .form-info-label {
    margin-right: 3px; }
  .form-info-cycle-instance {
    display: inline-block;
    width: 40%; }
  .form-info-period {
    text-transform: uppercase;
    width: 60%; }
  .form-info-value .text-bold {
    display: inline-block;
    font-size: 16px; }
