.table {
  border: 1px solid #d9d9d9;
  border-spacing: 0;
  font-family: "RobotoCondensed";
  width: 100%; }
  .table tr th {
    padding: 32px 8px 24px 8px; }
  .table tr td {
    padding: 16px 8px;
    line-height: 1.5em;
    font-weight: 100; }
  .table tr th:first-child,
  .table tr th:last-child,
  .table tr td:first-child,
  .table tr td:last-child {
    padding-right: 24px;
    padding-left: 24px; }
  .table-tbody tr:nth-child(odd) {
    background-color: rgba(91, 120, 142, 0.07); }
  .table-tr .message-area {
    width: 100%;
    background-color: rgba(91, 120, 142, 0.04);
    height: 76px; }
  .table-tr .message-box {
    background-color: rgba(91, 120, 142, 0.04);
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "RobotoCondensed";
    color: #464e54;
    padding-top: 20px; }
  .table-th {
    color: #148cc7;
    font-size: 18px;
    font-weight: normal;
    padding: 32px 8px 24px 8px;
    text-align: left;
    text-transform: uppercase; }
    .table-th:not(.unselected) {
      font-weight: 500; }
    .table-th.sortable {
      cursor: pointer; }
      .table-th.sortable.description {
        color: #71808b;
        font-size: 14px;
        margin-left: 10px;
        padding-top: 1px; }
    .table-th.unselected {
      color: #71808b; }
  .table-td {
    color: #71808b;
    font-size: 1em; }
  .table .table-head {
    font-size: 26px;
    font-weight: 300;
    letter-spacing: -0.6px;
    color: #156992; }
    .table .table-head .description {
      font-size: 14px;
      margin-left: 10px;
      display: inline-block;
      color: #71808b; }

.icon-arrow-bottom,
.icon-arrow-top {
  font-size: 0.6em;
  position: relative;
  top: 0;
  left: 4px; }
  .icon-arrow-bottom.unselected,
  .icon-arrow-top.unselected {
    color: #71808b;
    opacity: 0.4; }

.th-left {
  text-align: left; }

.th-right {
  text-align: right; }

.th-center {
  text-align: center; }

.table-tfoot .table-tr .table-td {
  text-align: center; }
  .table-tfoot .table-tr .table-td .center {
    display: block;
    margin-left: auto;
    margin-right: auto; }
