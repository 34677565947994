.switch-option-popper-tooltip-buttons {
  display: flex; }

.switch-option-popper-tooltip-message {
  padding-top: 0.2em; }
  .switch-option-popper-tooltip-message-action {
    text-transform: uppercase; }

.button-separator {
  padding-top: 1em;
  font-size: 1.125em;
  font-weight: bold; }
