.cycleInstance-payment-tr-cycles, .cycleInstance-payment-tr-period, .cycleInstance-payment-tr-average-commission, .cycleInstance-payment-tr-billing, .cycleInstance-payment-tr-total-commission, .cycleInstance-payment-tr-total-payment, .cycleInstance-payment-tr-download {
  font-family: "RobotoCondensed";
  text-align: center; }

.cycleInstance-payment-tr .negative {
  color: #d8232a; }

.cycleInstance-payment-tr-cycles {
  width: 10em; }

.cycleInstance-payment-tr-period {
  width: 17em;
  text-align: left; }

.cycleInstance-payment-tr-averageCommission {
  width: 9em; }

.cycleInstance-payment-tr-billing {
  width: 10em; }

.cycleInstance-payment-tr-total-commission {
  width: 12em; }

.cycleInstance-payment-tr-total-payment {
  width: 11em; }

.cycleInstance-payment-tr-download {
  width: 9em;
  transform: translateX(5px);
  text-align: left; }
  .cycleInstance-payment-tr-download a {
    color: #71808b;
    font-size: 13px;
    letter-spacing: -0.6px;
    margin-left: 3px;
    text-decoration: none; }
