@charset "UTF-8";
.breadcrumb {
  font-family: "RobotoCondensed";
  background-color: #f7f7f7;
  border: solid #d9d9d9;
  border-width: 1px 0;
  color: #71808b;
  font-size: 16px;
  line-height: 56px;
  padding: 0 25px; }
  .breadcrumb li {
    display: inline-block; }
    .breadcrumb li + li::before {
      padding: 8px;
      content: '›';
      color: #71808b;
      vertical-align: middle; }
    .breadcrumb li a {
      color: inherit;
      text-decoration: none;
      vertical-align: middle; }
    .breadcrumb li.active {
      color: #2f9bd4; }
      .breadcrumb li.active a {
        cursor: default; }
  .breadcrumb .icon.home {
    display: inline-block;
    height: 17px;
    width: 17px;
    background: url("../../resources/images/icon/icon-home.svg") no-repeat;
    background-size: 100%;
    margin-right: 8px;
    position: relative;
    top: 2px; }

.chamfer {
  position: relative;
  margin-left: 25px; }
  .chamfer .chamfer-border {
    position: absolute;
    border-right: 14px solid transparent;
    border-left: 14px solid transparent;
    border-top: 11px solid #d9d9d9; }
    .chamfer .chamfer-border::after {
      content: '';
      position: absolute;
      border-right: 13px solid transparent;
      border-left: 13px solid transparent;
      border-top: 10px solid #f7f7f7;
      top: -12px;
      left: -13px; }
