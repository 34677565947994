.content.commission-plans {
  position: relative;
  font-family: "RobotoCondensed"; }
  .content.commission-plans .standard-commission {
    position: absolute;
    right: 26px;
    top: 101px; }
    .content.commission-plans .standard-commission-label {
      color: #156992;
      margin-right: 16px; }
    .content.commission-plans .standard-commission-separator {
      position: absolute;
      width: 1px;
      height: 24px;
      opacity: 0.3;
      top: -2px;
      background-color: #b1b1b1;
      display: inline-block; }
    .content.commission-plans .standard-commission-value {
      font-size: 1em;
      font-weight: bold;
      font-style: normal;
      font-stretch: condensed;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-left: 14px;
      color: #e66700; }
  .content.commission-plans .table-th:not(.unselected) .description {
    color: #148cc7; }

.commission-plans-dialog-error .dialog-content {
  padding: 0 40px !important; }

.commission-plans-dialog-error-content {
  margin-top: 32px; }

.commission-plans-dialog-error-text {
  color: #71808b;
  font-size: 0.875em; }

.commission-plans-dialog-error-title {
  color: #c20000;
  font-size: 1em;
  margin-top: 16px; }

.commission-plans-dialog-error-text.bold {
  margin-top: 8px; }

.commission-plans-dialog-error-title.bold, .commission-plans-dialog-error-text.bold,
.commission-plans-dialog-error-title strong {
  font-weight: bold; }

.commission-plans-dialog-error-partners {
  background-color: #eae9e9;
  /* Validar com o Dede */
  margin-top: 16px;
  padding: 16px 16px 16px 32px;
  width: 100%;
  max-height: 200px; }
  .commission-plans-dialog-error-partners-title {
    color: #c20000;
    font-size: 0.875em;
    font-weight: bold;
    text-transform: uppercase; }
  .commission-plans-dialog-error-partners-content {
    margin-top: 8px;
    overflow: auto;
    width: 100%;
    max-height: 150px; }
  .commission-plans-dialog-error-partners-item {
    color: #5b788e;
    font-size: 0.75em;
    margin-bottom: 16px;
    text-transform: uppercase; }
  .commission-plans-dialog-error-partners-item strong {
    font-weight: bold; }

.header-message-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10; }

.header-message {
  color: #fff;
  opacity: 0.98;
  padding: 34px 20px 34px 48px; }
  .header-message i {
    color: #fff; }
    .header-message i::before {
      position: relative;
      top: 1px; }

.header-success-message,
.header-confirm-message {
  font-family: "RobotoCondensed";
  font-size: 24px;
  font-stretch: condensed;
  font-style: italic; }
  .header-success-message .icon-ok-check,
  .header-success-message .icon-warning,
  .header-confirm-message .icon-ok-check,
  .header-confirm-message .icon-warning {
    font-size: 26px;
    margin-right: 22px; }

.header-success-message {
  background-color: #009161; }
