.load-area-show {
  display: flex;
  font-family: "RobotoCondensed";
  font-size: 0.7em;
  justify-content: center;
  opacity: 0.78;
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  text-transform: none;
  text-align: center; }
  .load-area-show .loading-container {
    align-self: center; }

td .load-area-show {
  position: relative; }
  td .load-area-show span {
    display: none; }

.load-area-hide {
  display: none; }

@keyframes load-spiner {
  to {
    transform: rotate(360deg); } }

.load-spiner {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzBweCIgaGVpZ2h0PSIzMHB4IiB2aWV3Qm94PSIwIDAgMzAgMzAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUxLjEgKDU3NTAxKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5PdmFsIDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtZGFzaGFycmF5PSI1NywxMDMuNjY5OTk4MTY4OTQ1MyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iW0M0XVtBZGFvXS0tLUNhZGFzdHJvLWRlLWVuZGVyZWNvcy1kby1lc3RvcXVlLS0tVjEuMDEuMDEtbG9hZGluZyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY2OC4wMDAwMDAsIC0xMTYuMDAwMDAwKSIgc3Ryb2tlPSIjMDA1MDkzIiBzdHJva2Utd2lkdGg9IjUiPgogICAgICAgICAgICA8ZyBpZD0ibG9hZGluZyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTYuMDAwMDAwLCAxMTAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNNjY3LDMzIEM2NzMuNjI3NDE3LDMzIDY3OSwyNy42Mjc0MTcgNjc5LDIxIEM2NzksMTQuMzcyNTgzIDY3My42Mjc0MTcsOSA2NjcsOSBDNjYwLjM3MjU4Myw5IDY1NSwxNC4zNzI1ODMgNjU1LDIxIEM2NTUsMjcuNjI3NDE3IDY2MC4zNzI1ODMsMzMgNjY3LDMzIFoiIGlkPSJPdmFsLTIiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
  animation: load-spiner 0.8s linear infinite; }
