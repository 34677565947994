.failure-page {
  font-family: "RobotoCondensed";
  padding-top: 60px; }
  .failure-page .failure-page-content {
    margin: 60px 0 0 60px;
    height: 300px; }
    .failure-page .failure-page-content .title {
      color: #d8232a;
      font-size: 140px;
      font-weight: 900;
      text-transform: uppercase;
      margin: 0; }
    .failure-page .failure-page-content .failure {
      color: #005091;
      font-size: 40px;
      font-weight: 900;
      margin-top: 10px; }
  .failure-page .failure-page-actions {
    margin: 60px 0 0 60px; }
    .failure-page .failure-page-actions a {
      text-decoration: none; }
      .failure-page .failure-page-actions a .go-back-home {
        display: inline;
        padding: 20px;
        color: #fff;
        text-transform: uppercase;
        background-color: #005091; }
  .failure-page .failure-page-logo {
    background: url("../../resources/images/logo-2@2x.png") no-repeat;
    background-size: 100%;
    height: 172px;
    width: 214px;
    float: right;
    margin: 100px 60px 0 60px; }
