.partner-wizard {
  width: 624px; }
  .partner-wizard-header {
    margin-bottom: 0; }
  .partner-wizard-top {
    font-size: 12px;
    margin: 16px 0;
    padding: 15px 0 15px 30px;
    text-transform: uppercase;
    position: relative;
    color: #156992; }
    .partner-wizard-top-first {
      background-color: #d9d9d9; }
    .partner-wizard-top-first::after {
      border-color: transparent transparent transparent #d9d9d9; }
    .partner-wizard-top-second {
      background-color: #eae9e9; }
    .partner-wizard-top-second::after {
      border-color: transparent transparent transparent #eae9e9; }
    .partner-wizard-top-active {
      background-color: #148cc7;
      color: #fff;
      font-weight: bold; }
    .partner-wizard-top-active::after {
      border-color: transparent transparent transparent #148cc7; }
    .partner-wizard-top-partner::after {
      content: " ";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 22px 0 22px 15px;
      position: absolute;
      right: -15px;
      top: 0;
      z-index: 9; }
  .partner-wizard-navigation {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px; }
    .partner-wizard-navigation .btn {
      height: 50px; }
    .partner-wizard-navigation-cancel, .partner-wizard-navigation-cancel:hover {
      background-color: #fff;
      color: #5b788e;
      margin-right: 8px;
      width: 109px; }
    .partner-wizard-navigation-continue, .partner-wizard-navigation-continue:hover, .partner-wizard-navigation-continue.disable, .partner-wizard-navigation-continue.disable:hover {
      background-color: #148cc7;
      color: #fff;
      margin-right: 0;
      width: 161px; }
    .partner-wizard-navigation-back, .partner-wizard-navigation-back:hover {
      background-color: #dde2e8;
      color: #5b788e;
      margin-right: 8px;
      width: 109px; }
    .partner-wizard-navigation-save, .partner-wizard-navigation-save:hover {
      background-color: #009161;
      color: #fff;
      width: 161px; }

.icon-left {
  float: right; }
