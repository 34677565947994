.sellers-accounts-grid-header {
  float: right;
  margin-top: 52px;
  margin-bottom: 8px;
  display: flex; }

.table-sellers-accounts-info {
  margin: 25px 24px 8px 24px;
  display: flex; }
  .table-sellers-accounts-info-options {
    margin-left: 400px;
    text-align: right;
    text-transform: uppercase;
    margin-top: 10px;
    display: flex; }
    .table-sellers-accounts-info-options-separator {
      display: inline-block;
      color: #71808b;
      font-size: 1.5em;
      line-height: 10px;
      margin: 0 7px; }
    .table-sellers-accounts-info-options-send-file {
      color: #2f9bd4;
      cursor: pointer;
      font-family: "RobotoCondensed";
      font-size: 0.875em;
      margin-left: 3px;
      text-transform: uppercase; }
  .table-sellers-accounts-info-summary {
    font-size: 0.875em;
    display: inline-block;
    color: #71808b;
    margin-top: 10px;
    text-transform: uppercase; }
    .table-sellers-accounts-info-summary .text-bold {
      font-weight: bold; }
