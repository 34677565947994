.drop-zone {
  background-color: #fbfcfc;
  border: 1px dashed #d5d4d4;
  text-align: center;
  font-size: 16px;
  padding: 34px 0; }
  .drop-zone .drop-zone-description i {
    position: initial;
    margin-right: 8px;
    color: #2f9bd4;
    font-size: 20px; }
  .drop-zone .drop-zone-description .click-to-select {
    color: #2f9bd4; }

.highlight {
  background-color: #bcb9ec; }

.file-input {
  display: none; }
