.list-item .show-all {
  cursor: pointer; }
  .list-item .show-all-text {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    white-space: nowrap; }
  .list-item .show-all-badge {
    white-space: nowrap;
    padding: 1px 5px;
    border-radius: 2px;
    margin-left: 9px;
    background-color: #5b788e;
    font-size: 12px;
    font-weight: bold;
    color: #fff; }

.list-item .item-text {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 14px; }

.list-item .item-badge {
  white-space: nowrap;
  padding: 1px 5px;
  border-radius: 2px;
  margin-left: 9px;
  background-color: #148cc7;
  font-size: 12px;
  font-weight: bold;
  color: #fff; }
