.auto-complete-seller-filter {
  color: #156992;
  float: left;
  width: 30%; }

.sellers-table .th-name {
  width: 18.27%; }

.sellers-table .th-commission {
  width: 13.7%;
  text-align: center; }
  .sellers-table .th-commission span.description {
    margin-left: 4px; }

.sellers-table .th-plan-name {
  width: 30.67%; }

.sellers-table .th-edit {
  width: 11.5%; }

.sellers-table .tr-name {
  font-weight: 500; }

.sellers-table .tr-categories .categories-list {
  list-style: none; }

.sellers-table .tr-standard-commission, .sellers-table .tr-plan-name {
  font-weight: 500;
  font-size: 0.938em;
  line-height: 3.73;
  color: #464e54; }

.sellers-table .tr-standard-commission {
  font-weight: 500;
  text-align: center; }

.sellers-table .tr-edit {
  width: 8%; }
  .sellers-table .tr-edit .icon-text-edit {
    font-size: 1.438em;
    cursor: pointer;
    color: #156992; }
