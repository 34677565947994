.error-dialog-header {
  padding: 24px 20px 16px 20px; }

.dialog-header-title {
  font-size: 24px; }

.error-title {
  color: #c20000;
  font-weight: bold;
  margin-top: 16px; }

.error-text {
  margin-top: 16px; }

.error-promo-sku-list {
  margin-top: 16px; }
  .error-promo-sku-list-header {
    color: #c20000;
    display: flex;
    font-size: 16px;
    font-weight: bold; }
    .error-promo-sku-list-header div {
      font-weight: bold; }
  .error-promo-sku-list-items {
    display: flex;
    flex-direction: column;
    max-height: 136px;
    overflow: auto; }
  .error-promo-sku-list-item {
    align-items: center;
    display: flex;
    font-size: 14px;
    min-height: 48px; }
  .error-promo-sku-list .item-sku {
    width: 160px; }
    .error-promo-sku-list .item-sku-value {
      color: #5b788e;
      font-weight: bold;
      padding: 3px 0; }
    .error-promo-sku-list .item-sku-effect {
      color: #fff;
      background-color: #5b788e;
      font-weight: bold;
      font-size: 12px;
      padding: 2px 5px;
      border-radius: 2px;
      margin-left: 8px; }
  .error-promo-sku-list .item-period {
    width: 160px;
    display: flex;
    flex-direction: column; }
