.dialog-root > div {
  right: 10px; }

.dialog {
  max-width: 624px;
  padding: 32px 32px 24px 32px;
  border-radius: 3px;
  background-color: #fff; }
  .dialog * {
    font-family: "RobotoCondensed";
    font-style: normal;
    font-stretch: condensed;
    font-weight: 300;
    line-height: normal;
    letter-spacing: normal; }
  .dialog-header {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 8px;
    margin-bottom: 16px; }
    .dialog-header div {
      padding: 0; }
    .dialog-header .icon-close {
      float: right;
      font-size: 10px;
      color: #71808b;
      display: flex; }
    .dialog-header-subtitle-area {
      float: right; }
      .dialog-header-subtitle-area .dialog-header-subtitle {
        float: right;
        font-size: 12px;
        margin-top: 16px;
        color: #c20000; }
    .dialog-header .icon {
      margin-left: 6px;
      margin-right: 6px;
      font-size: 24px;
      color: #156992; }
    .dialog-header-title {
      font-size: 26px;
      letter-spacing: -0.6px;
      color: #156992; }
  .dialog-content {
    font-size: 14px;
    text-align: justify;
    line-height: 1.5;
    color: #5b788e; }
  .dialog-footer {
    margin-top: 24px; }
    .dialog-footer .btn {
      margin-left: 18px; }
