.plans-table .plan.th-commission {
  width: 8.44%;
  text-align: center; }
  .plans-table .plan.th-commission span.description {
    margin-left: 10px; }

.plans-table .plan.th-name {
  width: 21.77%; }

.plans-table .plan.th-categories {
  width: 16.06%; }

.plans-table .plan.th-sellers {
  width: 4.97%;
  text-align: center; }

.plans-table .plan.th-area {
  width: 8.44%; }

.plans-table .plan.th-details {
  width: 24.83%; }

.plans-table .plan.th-edit {
  width: 4.67%; }

.plans-table .plan-tr-standard-commission, .plans-table .plan-tr-sellers {
  text-align: center;
  font-size: 0.938em;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: 3.73;
  letter-spacing: normal;
  color: #464e54; }

.plans-table .plan-tr-categories .categories-list {
  list-style: none; }

.plans-table .plan-tr-edit {
  width: 8%; }
  .plans-table .plan-tr-edit .icon-text-edit {
    font-size: 1.438em;
    cursor: pointer;
    color: #156992; }

.plans-table .plan-tr-details {
  overflow: hidden;
  max-width: 150px; }
